/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.container-login-index-login .main {
  width: 368px;
  margin: 0 auto;
  color: white;
}
.container-login-index-login .main .icon {
  font-size: 24px;
  /*color: rgba(0, 0, 0, 0.2);*/
  color: white;
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.container-login-index-login .main .icon:hover {
  color: #1D9E60;
}
.container-login-index-login .main .other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.container-login-index-login .main .other .register {
  float: right;
  color: white;
}
.container-login-index-login .main .submit {
  width: 100%;
  margin-top: 24px;
  background: #ffffff;
  border-radius: 28px;
  color: #17C97C;
  border: 0;
}
.container-login-index-login .main .login-input input {
  background: rgba(7, 98, 60, 0.19);
  border-radius: 2px;
  border: 0;
  color: #fff;
}
.container-login-index-login .main .ant-form-item {
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .container-login-index-login .main {
    width: 640px;
  }
}
