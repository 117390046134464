.BillStatistics {
  background-color: #fff;
  width: 100%;
}
.BillStatistics .extraContent .ant-table-wrapper {
  width: 100%;
  overflow: auto;
}
.BillStatistics .extraContent .ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.BillStatistics .extraContent .ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.BillStatistics .extraContent .ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.BillStatistics .extraContent .ant-table-thead > tr > th,
.BillStatistics .extraContent .ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
.BillStatistics .extraContent .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.BillStatistics .extraContent .buttonStyle .ant-btn {
  padding: 0 5px !important;
}
.BillStatistics .extraContent .ant-checkbox-wrapper {
  padding: 5px;
}
.BillStatistics .ant-checkbox + span {
  padding-right: 0;
}
.BillStatistics .ant-checkbox-group {
  white-space: normal !important;
}
