/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.goods-sale .content {
  border-radius: 3px;
  background-color: #fff;
}
.goods-sale .content .header {
  padding: 16px 0px 0px;
}
.goods-sale .content .ant-table-wrapper {
  padding: 0 16px 40px;
}
.goods-sale .content .ant-table-thead > tr > th,
.goods-sale .content .ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
.goods-sale .close {
  color: #f5222d;
}
