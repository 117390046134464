/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.goods-alias-maintenance .goods-container .backRow {
  padding: 16px 16px 0px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
}
.goods-alias-maintenance .goods-container .backRow > div {
  font-weight: 700;
  color: #333333;
  text-align: center;
  line-height: 30px;
  height: 30px;
}
.goods-alias-maintenance .goods-container .backRow .productName {
  margin-left: 24px;
  font-size: 18px;
}
.goods-alias-maintenance .goods-container .backRow .categoryName {
  margin-left: 24px;
  font-size: 14px;
}
.goods-alias-maintenance .goods-container .goods-content {
  margin-top: 16px;
  background-color: #fff;
  padding: 16px 16px 0px;
}
.goods-alias-maintenance .goods-container .goods-content .goods-content-title {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  padding-bottom: 8px;
}
.goods-alias-maintenance .goods-container .goods-content .ant-table-wrapper {
  padding: 0 16px 40px;
}
.goods-alias-maintenance .goods-container .goods-content .ant-table-wrapper .ant-table-tbody .operating > div,
.goods-alias-maintenance .goods-container .goods-content .ant-table-wrapper .ant-table-tbody .operating > span {
  color: red;
}
.goods-alias-maintenance .goods-container .goods-content .ant-table-thead > tr > th,
.goods-alias-maintenance .goods-container .goods-content .ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
