/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.app {
  text-align: left;
}
a {
  color: #1D9E60;
}
a:active {
  color: #1d9e60;
}
a:hover {
  color: #1d9e60;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #1D9E60;
  border-color: #1D9E60;
}
.ant-pagination-item-active {
  border-color: #1D9E60;
}
.ant-pagination-item-active a {
  color: #1D9E60;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1D9E60;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1D9E60;
}
.ant-btn-primary {
  background-color: #1D9E60;
  border-color: #1D9E60;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #1D9E60;
  border-color: #1D9E60;
  color: #ffffff;
}
.ant-btn-link {
  color: #1D9E60;
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #1D9E60;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #1D9E60;
}
.ant-modal-header {
  background: #353535;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.ant-modal-title,
.ant-modal-close {
  color: #fff;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1D9E60;
  border-color: #1D9E60;
}
