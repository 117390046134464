/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.stallActiveInfo {
  background-color: #fff;
}
.stallActiveInfo .ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.stallActiveInfo .ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.stallActiveInfo .ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.stallActiveInfo .ant-advanced-search-form .ant-form-item-label {
  max-width: 100px;
}
.stallActiveInfo .ant-table-thead > tr > th,
.stallActiveInfo .ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
.stallActiveInfo .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.stallActiveInfo .buttonStyle .ant-btn {
  padding: 0 5px !important;
}
.stallActiveInfo .enable {
  width: 5px;
  height: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.stallActiveInfo .enable-true {
  background-color: #1D9E60;
}
.stallActiveInfo .enable-false {
  background-color: red;
}
.stallActiveInfo .template-download {
  position: relative;
  display: inline-block;
  font-size: 12px;
  top: -6px;
}
.stallActiveInfo .template-download > button {
  font-size: 12px;
}
.stallActiveInfo .template-download > button > span {
  display: block;
  line-height: 1;
}
