.ant-modal-body .ant-modal-confirm-body .anticon {
  display: none;
}
.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 18px;
  text-align: center;
}
.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content > div > p:nth-child(1) {
  text-align: center;
}
.ant-modal-body .ant-modal-confirm-btns {
  float: none;
  text-align: center;
}
.ant-modal-body .ant-modal-confirm-btns .ant-btn {
  padding: 0 50px;
}
.ant-modal-confirm-content > div > p:nth-child(1) {
  font-size: 18px;
  text-align: center;
  font-weight: 700 ;
  color: #333333;
}
.ant-modal-confirm-content > div > p:nth-child(7) {
  font-size: 18px;
  text-align: center;
  font-weight: 700 ;
  color: #333333;
}
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  background: #1D9E60;
  color: #FFFFFF;
}
