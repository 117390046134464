.caixm-phone {
  background-color: #fff;
  width: 100%;
}
.caixm-phone .extraContent .ant-table-wrapper {
  width: 100%;
  overflow: auto;
}
.caixm-phone .extraContent .ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.caixm-phone .extraContent .ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.caixm-phone .extraContent .ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.caixm-phone .extraContent .ant-advanced-search-form .ant-form-item-label {
  max-width: 100px;
}
.caixm-phone .extraContent .ant-table-thead > tr > th,
.caixm-phone .extraContent .ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
.caixm-phone .extraContent .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.caixm-phone .extraContent .buttonStyle .ant-btn {
  padding: 0 5px !important;
}
.caixm-phone .extraContent .ant-checkbox-wrapper {
  padding: 5px;
}
