/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.goods-sale-detail .goods-sale-detail-content > div.header {
  margin: 0 0 16px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  height: 54px;
}
.goods-sale-detail .goods-sale-detail-content > div.header > button {
  margin: 0 24px;
}
.goods-sale-detail .goods-sale-detail-content > div.header > span {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 1.5;
  margin-right: 32px;
}
.goods-sale-detail .goods-sale-detail-content > div.content {
  margin: 0;
  padding: 16px;
  background: #ffffff;
}
.goods-sale-detail .close {
  color: #f5222d;
}
.add-modal .login-form .ant-row .ant-form-item-label,
.newDeviceManagement .login-form .ant-row .ant-form-item-label {
  max-width: 120px;
}
