/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
.StallManagementDetail {
  font-size: 14px;
}
.StallManagementDetail .ant-tabs-nav .ant-tabs-tab {
  font-weight: 700;
}
.StallManagementDetail .detail-content {
  display: flex;
  padding: 20px 15px 50px;
}
.StallManagementDetail .detail-content > div img {
  width: 28px;
  margin-right: 15px;
}
.StallManagementDetail .detail-content > div .bold {
  font-weight: 700;
}
.StallManagementDetail .detail-content > div .stall-name {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.StallManagementDetail .detail-content > div > div {
  display: flex;
  margin-bottom: 15px;
}
.StallManagementDetail .detail-content > div > div > div {
  width: 30%;
}
.StallManagementDetail .detail-content .detail-sub-content {
  flex: 1;
}
.StallManagementDetail .backRow {
  background: #fff;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #E0E0E0;
}
.StallManagementDetail .backRow a {
  font-size: 16px;
  margin-left: 18px;
}
.StallManagementDetail .row1 {
  background: #fff;
  padding: 16px;
}
.StallManagementDetail .row1 .title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}
.StallManagementDetail .row1 .color1 {
  color: rgba(0, 0, 0, 0.45);
}
.StallManagementDetail .row1 .color2 {
  color: rgba(0, 0, 0, 0.85);
}
.StallManagementDetail .row1 .colLabel {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 26px;
}
.StallManagementDetail .row2 {
  background: #fff;
}
.StallManagementDetail .enable {
  padding: 1px 17px;
  border-radius: 20px;
  font-size: 16px;
  margin: 0 0 0 13px;
}
.StallManagementDetail .enable-true {
  color: #1D9E60;
  border: 1px solid #1D9E60;
}
.StallManagementDetail .enable-false {
  color: red;
  border: 1px solid red;
}
.global-header-body .ant-tabs-content .ant-tabs-tabpane {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
  min-height: 275px;
}
.global-header-body .picModal .ant-modal-close-x {
  color: rgba(0, 0, 0, 0.45);
}
