.PayAnalysis {
  display: inline-block;
  width: 100%;
}
.PayAnalysis .ant-form-item {
  display: inline-block;
}
.PayAnalysis .summery {
  display: flex;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #dbf3e7;
}
.PayAnalysis .summery .item + .item {
  margin-left: 16px;
}
