.device {
  background-color: #fff;
  padding: 20px 15px;
  font-size: 14px;
  width: 100%;
  text-align: left;
}
.device .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  padding-bottom: 10px;
}
.device .content {
  background-color: #f2f2f2;
  padding: 10px 15px 100px;
}
.device .content > div:first-child {
  text-align: right;
  padding-bottom: 10px;
}
.device .ant-table-wrapper {
  background: #fff;
}
.update-disable {
  color: #ccc;
  cursor: not-allowed;
}
.deviceManagement {
  background-color: #fff;
  padding: 0 15px;
  width: 100%;
}
.deviceManagement .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  padding-bottom: 10px;
}
.deviceModalStyle .ant-modal-close {
  color: #fff;
}
.deviceModalStyle .ant-modal-header {
  text-align: center;
  background: #000;
}
.deviceModalStyle .ant-modal-header .ant-modal-title {
  color: #fff;
}
.deviceModalStyle .ant-form {
  padding: 12px;
  border-radius: 6px;
}
.deviceModalStyle .ant-form .ant-form-item {
  display: flex;
  margin-bottom: 12px;
}
.deviceModalStyle .ant-form .ant-form-item-label {
  max-width: 100px;
}
.deviceModalStyle .ant-form .ant-form-item-control-wrapper {
  flex: 1;
}
.SignatureModal label,
.SignatureModal .ant-input-number {
  display: inline-block;
  width: 90% !important;
  margin-right: 10px;
  text-align: right;
}
.SignatureModal .ant-input-number-handler-wrap {
  display: none;
}
.contractManagement,
.basketSettings {
  width: 100%;
  padding: 0 15px;
  margin: 0 0;
}
.global-header-body .ant-tabs-content .ant-tabs-tabpane {
  display: flex;
  align-items: flex-start !important;
  justify-content: left;
  text-align: center;
  min-height: 275px;
}
.modalStyle .ant-modal-close {
  color: #fff;
}
.modalStyle .ant-modal-header {
  text-align: center;
  background: #000;
}
.modalStyle .ant-modal-header .ant-modal-title {
  color: #fff;
}
.modalStyle .ant-form {
  padding: 12px;
  border-radius: 6px;
}
.modalStyle .ant-form .ant-form-item {
  display: flex;
  margin-bottom: 12px;
}
.modalStyle .ant-form .ant-form-item-label {
  max-width: 100px;
}
.modalStyle .ant-form .ant-form-item-control-wrapper {
  flex: 1;
}
.modalStyle .ant-modal-footer {
  padding: 0;
}
.modalStyle .ant-btn-primary {
  background-color: #1d9e60;
  border-color: #1d9e60;
}
.basketSettingsModal .ant-form .ant-form-item-label {
  max-width: 120px;
}
.addtionFeeConfig {
  width: 100%;
  padding: 0 15px;
}
.addtionFeeConfig .table .tableItem .ball {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 8px;
  display: inline-block;
}
.addtionFeeConfig .table .tableItem .red {
  background-color: red;
}
.addtionFeeConfig .table .tableItem .green {
  background-color: #1d9e60;
}
.addtionFeeConfig .table .tableItem .optionText {
  font-size: 14px;
  color: #1d9e60;
  margin-right: 30px;
}
.addtionFeeConfig .table .tableItem .enableTrueText {
  font-size: 14px;
  color: red;
}
.addtionFeeConfig .table .tableItem .enableFalseText {
  font-size: 14px;
  color: #1d9e60;
}
.recommendStallList {
  width: 100%;
  padding: 0 15px;
}
.recommendStallList .header {
  margin-bottom: 10px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommendStallList .header .header-left {
  font-size: 15px;
  font-weight: bold;
}
.recommendStallList .header .header-left span {
  padding-left: 14px;
}
.recommendStallList .table .tableItem {
  cursor: pointer;
}
.recommendStallList .table .tableItem .ball {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 8px;
  display: inline-block;
}
.recommendStallList .table .tableItem .red {
  background-color: red;
}
.recommendStallList .table .tableItem .green {
  background-color: #1d9e60;
}
.recommendStallList .table .tableItem .optionText {
  font-size: 14px;
  color: #1d9e60;
  margin-right: 30px;
}
.recommendStallList .table .tableItem .enableTrueText {
  font-size: 14px;
  color: red;
}
.recommendStallList .table .tableItem .enableFalseText {
  font-size: 14px;
  color: #1d9e60;
}
.recommendStallList-modal {
  margin-top: 100px;
}
.recommendStallList-modal .recommendStallList-modal-content {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
}
.employeeManage-modal {
  margin-top: 100px;
}
.employeeManage-modal .employeeManage-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.employeeManage-modal .employeeManage-modal-content-title {
  font-size: 16px;
  font-weight: 500;
}
.employeeManage-modal .employeeManage-modal-content-desc {
  margin-top: 12px;
  font-size: 14px;
}
