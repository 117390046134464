.remove .mobileCashAppRemoveStallButton {
  background-color: red !important;
  color: white;
}
.remove .mobileCashAppRemoveStallButton :hover {
  color: white !important;
}
.remove .ant-btn:hover,
.remove .ant-btn:focus {
  color: white !important;
  border-color: white !important;
}
.ant-form .ant-form-item {
  display: flex;
  margin-bottom: 12px;
}
.ant-form .ant-form-item-label {
  max-width: 120px;
}
.ant-form .ant-form-item-control-wrapper {
  flex: 1;
}
