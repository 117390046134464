.advertising-management {
  padding: 16px;
  background: #fff;
}
.advertising-management .header {
  margin-bottom: 16px;
}
.advertising-management .header .ant5-space-item .ant5-form-item .ant5-row .ant5-col {
  display: contents;
}
.advertising-management .dot {
  position: relative;
}
.advertising-management .dot::before {
  position: absolute;
  content: ' ';
  margin-top: -4px;
  top: 50%;
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.advertising-management .green::before {
  background: green;
}
.advertising-management .red::before {
  background: red;
}
.advertising-management .btn {
  cursor: pointer;
}
