.sales-management .ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.sales-management .ant-advanced-search-form .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.sales-management .ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.sales-management .ant-advanced-search-form .ant-form-item-label {
  max-width: 100px;
}
.sales-management table {
  background-color: #fff;
}
